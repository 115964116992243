import { default as _91id_9301aye3Pm77Meta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexAxFzWCZLyDMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93UDbW2FvkvbMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexlgpodFb3wkMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsolC44EdXs5Meta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsW9shOV9bidMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexUpGKbkeL4hMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexbAy2USXr5wMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardEO9yhsMZxnMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/dashboard.vue?macro=true";
import { default as entrancesySbcuqeM0Meta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/entrance.vue?macro=true";
import { default as indexF2IJrg2b49Meta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/index.vue?macro=true";
import { default as login6lcxTxnSpDMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/login.vue?macro=true";
import { default as indexACpZCVG5NKMeta } from "/home/forge/merchants.smake.construction/releases/v1.28.0/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_9301aye3Pm77Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAxFzWCZLyDMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexAxFzWCZLyDMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93UDbW2FvkvbMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexlgpodFb3wkMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsolC44EdXs5Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsW9shOV9bidMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexUpGKbkeL4hMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbAy2USXr5wMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexbAy2USXr5wMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardEO9yhsMZxnMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardEO9yhsMZxnMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrancesySbcuqeM0Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: login6lcxTxnSpDMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: login6lcxTxnSpDMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.28.0/pages/index.vue").then(m => m.default || m)
  }
]